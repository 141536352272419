@import '@core/styles/vars';

.SuggestionsEditor {
  $padding: 15px 31px;
  :global(.rm-Sidebar) {
    margin-top: 7.5px;
  }
  &-content {
    width: 100%;
    padding: $padding;
  }
  &-form {
    input,
    textarea {
      --SuggestedEdits-input-hover-bg: transparent;
      all: unset;
      width: 100%;
      border-radius: var(--border-radius);
      transition: 0.3s;
      background-color: var(--SuggestedEdits-input-hover-bg);
      box-shadow: 0 0 0 5px var(--SuggestedEdits-input-hover-bg);
      &:hover,
      &:focus {
        --SuggestedEdits-input-hover-bg: #{rgba($gray50, 0.1)};
      }
    }
    & + hr {
      margin: 20px 0 26px;
    }
  }
  &-header {
    display: flex;
    flex-flow: nowrap column;
    &-actions {
      display: flex;
      align-items: center;
      gap: 0.33em;
    }
    &-title {
      margin: 0.5em 0.5em 0.5em 0;
      flex: 1;
    }
    textarea#{&}-excerpt {
      margin: 0;
      font-size: 18px;
    }
    &-title,
    textarea#{&}-excerpt {
      max-width: calc(100% - var(--hub-toc-width));
    }
  }
  &-body {
    $offset: 10px;
    margin-right: calc(#{$offset} + var(--hub-toc-width));
    max-width: calc(100vw - (var(--hub-sidebar-width) + var(--hub-toc-width) + (#{nth($padding, 2)} * 2) + #{$offset}));
  }
  &-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    :global(.Spinner) {
      font-size: 3em;
    }
  }
  :global {
    .markdown-body {
      .callout.callout {
        // on the hub, callouts rendered in the new editor seem to gain
        // extra padding around the edges; this override fixes it.
        padding: 1.33em 1.33em 1.33em 0 !important;
      }
    }
  }
}
